import React from 'react'
import logo from "../images/a7dev_logo_2_transparent.webp"
import NavbarITem from './NavbarITem'
import { faBars, faClose, faHome, faInfoCircle, faMessage, faMobileAndroid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HERO_KEY, ABOUT_KEY, CONTACT_KEY, WORK_KEY, CLOSE_KEY } from '../utils/Constants.js';


function NavBar({ handleClick }) {

    const showMobileMenu = () => {
        const menu = document.getElementById("mobile-navbar-menu")
        menu.classList.remove("mobile-navbar-menu-hidden")
        menu.classList.add("mobile-navbar-menu-displayed")
    }

    const hideMobileMenu = () => {
        const menu = document.getElementById("mobile-navbar-menu")
        menu.classList.remove("mobile-navbar-menu-displayed")
        menu.classList.add("mobile-navbar-menu-hidden")
    }

    const onMobileItemClick = (value) => {
        hideMobileMenu();
        if (value !== CLOSE_KEY) {
            handleClick(value)
        }
    }

    return (
        <div className='navbar'>
            <img className='navbar-logo' src={logo} alt="logo" />
            <ul>
                <li onClick={(e) => { e.preventDefault(); handleClick(HERO_KEY) }}><NavbarITem icon={faHome} title={"Home"} /></li>
                <li onClick={(e) => { e.preventDefault(); handleClick(WORK_KEY) }}><NavbarITem icon={faMobileAndroid} title={"Our apps"} /></li>
                <li onClick={(e) => { e.preventDefault(); handleClick(ABOUT_KEY) }}><NavbarITem icon={faInfoCircle} title={"About"} /></li>
                <li onClick={(e) => { e.preventDefault(); handleClick(CONTACT_KEY) }}><NavbarITem icon={faMessage} title={"Contact"} /></li>
            </ul>

            <div onClick={(e) => { e.preventDefault(); showMobileMenu() }} className="menu-icon-div">
                <FontAwesomeIcon className='menu-icon' icon={faBars} />
            </div>

            <ul id='mobile-navbar-menu'>
                <FontAwesomeIcon onClick={hideMobileMenu} className='close-navbar-icon' icon={faClose} />
                <li onClick={(e) => { e.preventDefault(); onMobileItemClick(HERO_KEY) }}><NavbarITem icon={faHome} title={"Home"} /></li>
                <li onClick={(e) => { e.preventDefault(); onMobileItemClick(WORK_KEY) }}><NavbarITem icon={faMobileAndroid} title={"Our apps"} /></li>
                <li onClick={(e) => { e.preventDefault(); onMobileItemClick(ABOUT_KEY) }}><NavbarITem icon={faInfoCircle} title={"About"} /></li>
                <li onClick={(e) => { e.preventDefault(); onMobileItemClick(CONTACT_KEY) }}><NavbarITem icon={faMessage} title={"Contact"} /></li>
            </ul>

        </div>
    )
}

export default NavBar